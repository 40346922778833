<template>
  <div class="px-0">
    <b-card
      class="page-card"
      header-class="page-card-with-logo page-card-header"
      body-class="page-card-body"
    >
      <div slot="header">
        <b-row class="fit-to-center">
          <b-col cols="6">
            <div class="page-header-left">
              <div class="pr-1 header-icon">
                <i class="cuis-spreadsheet card-icon-font"></i>
              </div>
              <div>
                <h5 class="mb-0"><b>FIN Reporter</b> - <i>Select Report</i></h5>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="page-header-right">
              <header-logo
                logoType="fi"
                :logoUrl="s3LogoUrl"
                :logoId="selectedInstitution.id"
              ></header-logo>
              <div>
                <h5 class="mb-0">{{ selectedInstitution.name }}</h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-row class="justify-content-center">
          <b-col cols="12">
            <v-wait for="reportsLoading">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="10"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <b-card-group deck>
                <b-card
                  class="std-border reports-card"
                  body-class="p-1"
                  header-class="pl-3"
                >
                  <div slot="header">
                    <h6 class="mb-0"><b>My Custom Reports</b></h6>
                  </div>
                  <v-wait for="customReportsLoading">
                    <template slot="waiting">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="12"
                        ></content-placeholders-text>
                      </content-placeholders>
                    </template>
                    <b-list-group class="selectable-list-group">
                      <b-list-group-item v-if="this.customReports.length === 0">
                        <i>No Reports found</i>
                      </b-list-group-item>

                      <b-list-group-item
                        v-else
                        v-for="(report, index) in orderedCustomReports"
                        :key="index"
                        @click="selectReport(report, 'custom')"
                        :class="[
                          { selected: isReportSelected(report, 'custom') },
                          'p-2 '
                        ]"
                      >
                        <span :id="`cr-${report.id}`">
                          {{ report.name }}
                          <span class="text-muted" v-if="isFinApiEnterpriseUser"
                            >[{{ report.id }}]
                          </span>
                          <span
                            class="text-muted"
                            v-if="report.group_ids && report.group_ids.length"
                          >
                            <b-badge pill variant="secondary" class="px-1"
                              >Shared
                            </b-badge>
                          </span>
                        </span>
                        <span
                          class="p-0 pl-1 pb-1  favorite__star__selected"
                          v-if="report.category === 'key_metrics'"
                          ><b> &#x2605;</b></span
                        >
                        <span class="manage--report-group">
                          <button
                            type="submit"
                            class="pt-0 float-right btn btn-secondary btn-sm mx-1"
                            @click.stop="deleteCustomReport(report)"
                          >
                            Delete
                          </button>
                          <button
                            type="submit"
                            class="pt-0 float-right btn btn-primary btn-sm mx-1"
                            @click.stop="editCustomReport(report)"
                          >
                            Edit
                          </button>
                        </span>
                        <b-popover
                          :target="`cr-${report.id}`"
                          triggers="hover"
                          placement="left"
                          v-if="report.description"
                        >
                          {{ report.description }}
                        </b-popover>
                      </b-list-group-item>
                    </b-list-group>
                  </v-wait>
                </b-card>
                <b-card
                  class="std-border reports-card"
                  body-class="p-1"
                  header-class="pl-3"
                >
                  <div slot="header">
                    <h6 class="mb-0">
                      <b>{{ navigatorTitleText }}</b>
                    </h6>
                  </div>
                  <b-list-group class="selectable-list-group">
                    <b-list-group-item
                      v-if="this.navigatorReports.length === 0"
                    >
                      <i>No Reports found</i>
                    </b-list-group-item>

                    <b-list-group-item
                      v-else
                      v-for="(report, index) in navigatorReports"
                      :key="index"
                      @click="selectReport(report, 'finavigator')"
                      :class="[
                        { selected: report.id === metric_args.reportTypeId },
                        'p-2'
                      ]"
                    >
                      {{ report.name }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-card-group>
            </v-wait>
          </b-col>
        </b-row>
      </div>

      <b-row class="text-center pt-1">
        <b-col md="12" lg="10" class="mt-3">
          <b-button
            size=""
            variant="primary"
            class="fw-btn mx-1"
            @click="createCustomReport"
            >Create Report</b-button
          >

          <router-link
            tag="button"
            :to="{
              path: 'metrics',
              query: {
                id: metric_args.reportId,
                report_id: metric_args.reportTypeId,
                report_type: metric_args.reportType
              }
            }"
            class="btn btn-primary fw-btn"
            :disabled="!enableContinue"
            @click.native="trackReportView"
          >
            View Report
          </router-link>
        </b-col>

        <b-col md="12" lg="2" class="text-right mt-3">
          <span>
            <i> Default View: </i>
            <b-dropdown id="ddown-offset" :text="selectedView" class="fw-btn">
              <b-dropdown-item @click="updateMultiView">{{
                isTechnographicReport
                  ? 'Multi-Service View'
                  : 'Multi-Metric View'
              }}</b-dropdown-item>
              <b-dropdown-item @click="updateSingleView">{{
                isTechnographicReport
                  ? 'Single-Service View'
                  : 'Single-Metric View'
              }}</b-dropdown-item>
              <b-dropdown-item
                v-if="isCustomReport && !isTechnographicReport"
                @click="updatePeriodView"
                >Period View</b-dropdown-item
              >
            </b-dropdown>
          </span>
        </b-col>
      </b-row>

      <b-row v-if="showReportMetrics()">
        <b-col md="12">
          <b-card
            class="std-border metrics-card mt-4"
            body-class="p-1"
            header-class="pl-3"
          >
            <div slot="header">
              <h6 class="mb-0"><b>Report Metrics</b></h6>
            </div>
            <v-wait for="metricsLoading">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="15"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <b-list-group class="selectable-list-group">
                <b-list-group-item
                  v-for="(report, index) in metric_args.metricsList"
                  :key="index"
                  class="p-2"
                >
                  {{ report.name }}
                </b-list-group-item>
              </b-list-group>
            </v-wait>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <report-writer-modal
      renderedIn="FIN Reporter"
      @reportModalClosed="reportClosed"
    >
    </report-writer-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import router from '@/router'
import { mapState, mapGetters, mapMutations } from 'vuex'
import HeaderLogo from '@/modules/core/components/HeaderLogo'
import { sortBy } from 'lodash'
//api
import selfServeReportsAPI from '@/api/finapps/self_serve_reports'
//ui components
import ReportWriterModal from '@/modules/report_writer/components/ReportWriterModal'

export default {
  name: 'Report',
  components: {
    HeaderLogo,
    ReportWriterModal
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      document.title = 'FIN Reporter'

      if (vm.selectedInstitution.id && vm.selected_peer_group) {
        vm.$wait.start('reportsLoading')
        vm.$http
          .post(
            'api/reports',
            {
              bank_id: vm.selectedInstitution.id,
              peer_group_id: vm.selected_peer_group
            },
            {
              handleErrors: true
            }
          )
          .then(res => {
            vm.$store.commit('metric_args', res.data.reports.report_id)
            vm.$store.commit('setReportToken', res.data.reports.report_token)
            vm.$store.commit(
              'setBreakPointProgress',
              res.data.reports.breakpoint_progress
            )
            vm.$store.commit(
              'setReportProgress',
              res.data.reports.report_progress
            )
            vm.customReports = res.data.reports.custom_reports
            vm.navigatorReports = res.data.reports.finavigator_reports
            vm.fiType = res.data.reports.fi_type
            vm.$wait.end('reportsLoading')
          })
      } else if (vm.selectedInstitution.id) {
        Vue.toasted.show('Please select peer group.', {
          icon: 'chain-broken',
          type: 'error'
        })
        router.push('/analytics/reporter/select_peer_group')
      } else {
        Vue.toasted.show('Please select institution.', {
          icon: 'chain-broken',
          type: 'error'
        })
        router.push('/analytics/reporter/select_institution')
      }
      vm.$store.dispatch('setReportView', 'multi')
    })
  },
  mounted() {
    if (this.metric_args.reportTypeId) {
      this.loadReportMetrics()
    }
  },
  data() {
    return {
      customReports: [],
      navigatorReports: [],
      fiType: 'bank'
    }
  },
  computed: {
    ...mapState(['partnerSite']),
    ...mapGetters([
      'selectedInstitution',
      'selected_peer_group',
      'isMultiMetricView',
      'reportView',
      'metric_args',
      's3LogoUrl',
      'isCustomReport',
      'isTechnographicReport'
    ]),
    ...mapGetters('Ability', ['isFinApiEnterpriseUser']),
    enableContinue() {
      return ![undefined, null].includes(this.metric_args.reportTypeId)
    },
    selectedView() {
      if (this.reportView == 'period') {
        return 'Period View'
      } else if (this.reportView == 'multi') {
        return this.isTechnographicReport
          ? 'Multi-Service View'
          : 'Multi-Metric View'
      } else {
        return this.isTechnographicReport
          ? 'Single-Service View'
          : 'Single-Metric View'
      }
    },
    orderedCustomReports() {
      return sortBy(this.customReports, report => report.name.toLowerCase())
    },
    navigatorTitleText() {
      return this.partnerSite ? 'Default Reports' : 'FI Navigator Reports'
    }
  },
  methods: {
    ...mapMutations(['unsetSelectedReport']),
    ...mapMutations('ReportWriter', [
      'clearSelectedReport',
      'setReportActualFIType',
      'setCustomReport',
      'setReportFIType'
    ]),
    selectReport: function(report, type) {
      this.$store.commit('update_metric_args', {
        reportTypeId: report.id,
        reportType: type,
        technographicReport: report.is_technographics_only || false
      })
      this.loadReportMetrics()
    },
    loadReportMetrics: function() {
      this.$wait.start('metricsLoading')

      this.$http
        .get('/api/self_serve_reports/metric_list', {
          params: {
            id: this.metric_args.reportTypeId,
            type: this.metric_args.reportType,
            fin_reporter: true
          },
          handleErrors: false
        })
        .then(
          res => {
            this.$store.commit('update_metric_args', {
              metricsList: res.data.metrics
            })
            this.$wait.end('metricsLoading')
          },
          () => {
            this.$store.commit('update_metric_args', { metricsList: [] })
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        )
    },
    updateMultiView: function() {
      this.$store.dispatch('setReportView', 'multi')
    },
    updateSingleView: function() {
      this.$store.dispatch('setReportView', 'single')
    },
    updatePeriodView: function() {
      this.$store.dispatch('setReportView', 'period')
    },
    showReportMetrics() {
      return this.metric_args.reportTypeId
    },
    trackReportView() {
      this.$ahoy.track('report_view', {
        fi_id: this.selectedInstitution.id,
        report_id: this.metric_args.reportId,
        report_type: 'FIN-R'
      })
    },
    isReportSelected(report, reportType) {
      return (
        this.metric_args.reportTypeId === report.id &&
        this.metric_args.reportType === reportType &&
        this.metric_args.technographicReport === report.is_technographics_only
      )
    },
    createCustomReport() {
      this.clearSelectedReport()
      this.unsetSelectedReport()
      this.setReportFIType(this.fiType)
      this.$bvModal.show('irReportWriterModal')
    },
    editCustomReport(report) {
      this.selectReport(report, 'custom')
      this.setCustomReport(report)
      this.setReportActualFIType(report.fi_type)
      this.$bvModal.show('irReportWriterModal')
    },
    deleteCustomReport(report) {
      this.selectReport(report, 'custom')

      return this.$dialog
        .confirm(
          {
            title: 'Alert',
            body: `Are you sure, you want to delete Report - ${report.name} ?`
          },
          {
            okText: 'Delete',
            cancelText: 'Cancel'
          }
        )
        .then(() => {
          return selfServeReportsAPI
            .delete(this.metric_args.reportTypeId)
            .then(() => {
              let reportIndex = this.customReports.findIndex(
                report => report.id === this.metric_args.reportTypeId
              )
              this.customReports.splice(reportIndex, 1)
              this.$toasted.global.action_success(
                'Report deleted successfully.'
              )
              this.unsetSelectedReport()
            })
        })
        .catch(() => {})
    },
    reportClosed() {
      this.$wait.start('customReportsLoading')

      selfServeReportsAPI
        .userReportsForFIType(this.fiType, this.selectedInstitution.id)
        .then(res => {
          this.customReports = res.custom_reports
          if (this.metric_args.reportTypeId) {
            let hasSelectedReport = this.customReports.find(report =>
              this.isReportSelected(report, 'custom')
            )
            hasSelectedReport
              ? this.loadReportMetrics()
              : this.unsetSelectedReport()
          }
        })
        .finally(() => {
          this.$wait.end('customReportsLoading')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.reports-card,
.metrics-card {
  .card-body {
    height: 20rem;
    overflow: auto;
  }
}

.metrics-card {
  .card-body {
    max-height: 20rem;
    overflow: auto;
    text-transform: uppercase;
  }
}

.view-select {
  display: inline-block;
}

.manage--report-group {
  display: none;

  .btn {
    border-radius: 0.25rem;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 4rem;
  }
}

.selectable-list-group {
  .list-group-item {
    &:hover {
      .manage--report-group {
        display: inline;
      }
    }
  }
}
.favorite__star__selected {
  color: #a7ca77;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1rem;
  cursor: pointer;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
  -webkit-text-stroke: 0.6px grayscale($color: #686262fb);
}
</style>
